import { Component, inject } from '@angular/core';
import { WaitingService } from '../../services/waiting.service';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'vela-waiting-layer',
    templateUrl: './waiting-layer.component.html',
    styleUrls: ['./waiting-layer.component.scss'],
    standalone: true,
    imports: [AsyncPipe],
})
export class WaitingLayerComponent {
  waitingService = inject(WaitingService);
}
